<template>
  <b-card>
    <b-card-title class="d-flex justify-content-between mb-0">
      <span>Atendimentos</span>
      <feather-icon
      icon="BellIcon"
      size="21"
      class="text-warning"
      :badge="atendimentosAbertos.length"
      badge-classes="badge-warning badge-glow"
    />
  
    </b-card-title>
    <b class="text-muted">Em aberto</b>
    <b-list-group class="mt-2">
      <b-list-group-item
        v-for="(item, index) in atendimentosAbertos"
        :key="index"
        class="pb-0"
      >
        <b-row class="d-flex justify-content-between">
          <b-col md="3">
            <small>
              {{ item.data | formataDataHora }}
            </small>
          </b-col>
          <b-col md="auto" >
            <small>{{ item.cliente }}</small>
          </b-col>
          <b-col md="auto">
            <small>{{ item.assunto }}</small>
          </b-col>
          <b-col md="auto">
            <b-button
              v-b-toggle="'collapse-' + index"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class="btn-icon p-0 mb-1 float-right"
              size="sm"
              variant="flat-secondary"
            >
              <feather-icon icon="ChevronDownIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-collapse :id="'collapse-' + index">
          <b-row class="pb-1">
            <b-col>
              <small> {{ item.descricao }} </small>
              <b-badge 
                href="#" 
                class="float-right" 
                variant="primary"
                @click="concluir(item, index)"
              >
                Concluir
              </b-badge>
            </b-col>
          </b-row>
        </b-collapse>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BTable,
  BListGroup,
  BListGroupItem,
  BBadge,
  BRow,
  BCol,
  BButton,
  VBToggle,
  BCollapse,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapState } from "vuex";
import moment from "moment";
export default {
  components: {
    BCard,
    BCardTitle,
    BTable,
    BListGroup,
    BListGroupItem,
    BBadge,
    BRow,
    BCol,
    BButton,
    BCollapse,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
    'b-modal': VBModal
  },
  mounted() {
    this.$store.dispatch("dashboard_atendimento/listaAtendimentosAbertos", 1);
  },
  computed: {
    ...mapState({
      atendimentosAbertos: (state) => state.dashboard_atendimento.atendimentos,
    }),
  },
  methods: {
    concluir(item, index) {
       this.$bvModal
        .msgBoxConfirm("Deseja realmente concluir esse atendimento?", {
          title: "Concluir Atendimento",
          size: "sm",
          okVariant: "primary",
          okTitle: "Sim",
          cancelTitle: "Não",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((valor) => {
          if (valor) {
            this.$http
              .put("adm/atendimento/concluir/" + item.id)
              .then((res) => {
                if (res.data) {
                  item.concluido = this.$getDate.dataHoraHoje();
                  let response = {
                    index: index,
                    dados: item,
                  };
                  this.$store.commit(
                    "atendimento/EDITAR_ATENDIMENTO",
                    response
                  );
                  this.$store.commit(
                    "dashboard_atendimento/DELETAR_ATENDIMENTO",
                    index
                  );
                }
              });
          }
        })
    }
  },
  filters: {
    formataDataHora(data) {
      if (!data) return;

      return moment(data).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style>
</style>