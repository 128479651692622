<template>
<div>
  <b-row>
    <b-col>
      <card-atendimentos/>
    </b-col>
    <b-col v-if="me.adm_permissoes > 3">
      <card-pedidos-retiradas/>
    </b-col>
    <b-col>
      <card-ticket-medio/>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <card-teste-gratis-x-assinantes/>
    </b-col>
  </b-row>
</div>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol, } from 'bootstrap-vue'
import CardAtendimentos from './dashboard/CardAtendimentos.vue'
import CardPedidosRetiradas from './dashboard/CardPedidosRetiradas.vue'
import { mapState } from 'vuex'
import CardTesteGratisXAssinantes from './dashboard/CardTesteGratisXAssinantes.vue'
import CardTicketMedio from './dashboard/CardTicketMedio.vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    CardAtendimentos,
    CardPedidosRetiradas,
    CardTesteGratisXAssinantes,
    CardTicketMedio,

  },
  computed: {
    ...mapState({
      me: (state) => state.user.user
    })
  },

}
</script>

<style>

</style>
