<template>
  <b-card>
    <div class="truncate">
        <b-avatar class="mb-1 float-right" variant="light-warning" size="45">
          <feather-icon size="21" icon="TagIcon" />
        </b-avatar>
        <h4 class="mb-25 font-weight-bolder">R$ {{ ticket_medio | formataMoeda}}</h4>
        <b class="text-muted">Ticket Médio</b>
      </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar, } from "bootstrap-vue";
export default {
 components: {
  BCard,
  BAvatar,
 },
 data() {
  return {
    ticket_medio: 0,
  }
 },
 mounted() {
  this.$http.get('adm/dashboard/ticket-medio').then((res) => {
    const mensal = res.data.valor_mensais / res.data.quantidade_mensais
    const anual = (res.data.valor_anuais / 12) / res.data.quantidade_anuais
    this.ticket_medio = (mensal + anual) / 2
  })
 },
 filters: {
    formataMoeda(valor) {
      if (!valor) return "0,00";

      let vl = parseFloat(valor);
      return vl.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
}
</script>

<style>

</style>