<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and legend -->
      <b-card-title class="mb-50">
        Teste Grátis <span style="font-weight: lighter">x </span> Assinantes
      </b-card-title>
      <!--/ title and legend -->
      <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
        <div class="d-flex align-items-center">
          <b-form-group class="mb-0">
            <v-select
              v-model="selected"
              label="title"
              :options="anos"
              class="select-size-sm"
              :clearable="false"
              style="width: 91px"
              @input="alterouFiltro()"
            />
          </b-form-group>
        </div>
      </div>
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="300"
        :options="columnChart.chartOptions"
        :series="columnChart.series"
      />
    </b-card-body>
    <modal-assinantes :assinaturas="assinaturas"/>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormGroup,
  BModal,
  VBModal,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";
import { mapState } from "vuex";
import ModalAssinantes from './ModalAssinantes.vue';

export default {
  components: {
    BCard,
    BCardBody,
    BFormGroup,
    BCardHeader,
    VueApexCharts,
    BCardTitle,
    vSelect,
    ModalAssinantes,
    BModal,
  },
  directives: {
    'b-modal': VBModal
  },

  data() {
    return {
      assinaturas: [],
      selected: { title: this.$getDate.anoAtual().toString(), value: this.$getDate.anoAtual().toString() },
      columnChart: {
        series: [
          {
            name: "Teste Grátis",
            data: [90, 120, 55, 100, 80, 125, 175, 70, 88, 180, 142, 180],
          },
          {
            name: "Assinantes",
            data: [85, 100, 30, 40, 75, 90, 30, 110, 62, 20, 98, 100],
          },
        ],
        chartOptions: {
          chart: {
            events: {
              click: function (event, chartContext, config) {
                if (config.seriesIndex === 1) {
                  return this.buscaAssinantes(config.dataPointIndex)
                }
                
              }.bind(this),
            },
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          colors: ["#d2b0ff", "#826af9"],
          plotOptions: {
            bar: {
              columnWidth: "50%",
              colors: {
                backgroundBarRadius: 10,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            position: "top",
            fontSize: "14px",
            fontFamily: "Montserrat",
            horizontalAlign: "left",
          },
          stroke: {
            show: true,
            colors: ["transparent"],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [
              "Jan",
              "Fev",
              "Mar",
              "Abr",
              "Mai",
              "Jun",
              "Jul",
              "Ago",
              "Set",
              "Out",
              "Nov",
              "Dez",
            ],
          },
          yaxis: {
            // opposite: isRtl,
          },
          fill: {
            opacity: 1,
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      teste_gratis: (state) =>
        state.dashboard_teste_gratis_x_assinantes.teste_gratis,
      assinantes: (state) => state.dashboard_teste_gratis_x_assinantes.assinantes,
    }),
    anos() {
      let result = [];
      const data = new Date();
      let dataInicio = 2017;
      const anoAtual = data.getFullYear();

      while (dataInicio <= anoAtual) {
        result.push({
          value: dataInicio,
          title: dataInicio,
        });
        dataInicio += 1;
      }

      return result;
    },
  },
  watch: {
     teste_gratis() {
      this.columnChart.series = [
        {
          name: "Teste Grátis",
          data: this.teste_gratis,
        },
        {
          name: "Assinantes",
          data: this.assinantes,
        },
      ];
    },
  },
  mounted() {
    this.buscaDados();
  },
  methods: {
    alterouFiltro() {
      this.buscaDados();
    },
    buscaDados() {
      this.$store.dispatch(
        "dashboard_teste_gratis_x_assinantes/listaAssinaturas",
        this.selected.value
      );
    },
    buscaAssinantes(dataPointIndex) {
      this.$http.get(`adm/dashboard/busca-assinantes/${(dataPointIndex +1)}`).then((res) => {
        if (res.data) {
          this.assinaturas = res.data
          this.$bvModal.show('modalAssinantes')
        }
      })
    }
  },
};
</script>