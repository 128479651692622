<template>
  <b-card>
    <b-card-title class="d-flex justify-content-between mb-0">
      <span>Pedidos</span>
      <feather-icon
        icon="BellIcon"
        size="21"
        class="text-primary"
        :badge="pedidos.length"
        badge-classes="badge-primary badge-glow"
      />
    </b-card-title>
    <b class="text-muted">de Retirada</b>
    <b-list-group class="mt-2">
      <b-list-group-item
        v-for="(item, index) in pedidos"
        :key="index"
      >
        <b-row class="d-flex justify-content-between">
          <b-col md="3">
            <small>
              {{ item.data_pedido | formataDataHora }}
            </small>
          </b-col>
          <b-col md="auto">
            <small>{{ item.cliente }}</small>
          </b-col>
          <b-col md="auto">
            <small>R$ {{ item.valor | formataMoeda }}</small>
          </b-col>
          <b-col md="auto">
            <b-badge
              href="#"
              class="float-right"
              variant="primary"
              @click="efetuarRetirada(item, index)"
            >
              Concluir
            </b-badge>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <modal-efetuar-retirada :dadosCliente="dadosCliente" @concluido="concluido"/>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BBadge,
  BListGroup,
  BListGroupItem,
  BButton,
  BCollapse,
  VBModal,
  VBToggle,
} from "bootstrap-vue";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import moment from 'moment';
import ModalEfetuarRetirada from '../financeiro/pedidos-retirada/ModalEfetuarRetirada.vue';
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BBadge,
    BListGroup,
    BListGroupItem,
    BButton,
    BCollapse,
    ModalEfetuarRetirada,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      dadosCliente: {
        item: {
          cliente: '',
          valor: 0,
          num_comprovante: '',
          data_deposito: '',
        },
        contas: {
          nome: '',
          cod_banco: '',
          num_agencia: '',
          num_conta: '',
          chave_pix: '',
        }
      },
    }
  },
  mounted() {
    this.$store.dispatch("dashboard_retiradas/listaPedidosRetirada");
  },
  computed: {
    ...mapState({
      pedidos: (state) => state.dashboard_retiradas.retiradas,
    }),
  },
  methods: {
    efetuarRetirada(item) {
      this.$http.get('adm/retiradas/contas/'+item.id).then(res => {
        let contas = res.data[0]
        let payload = {
          item,
          contas
        }
        this.dadosCliente = payload
      })
      this.$bvModal.show("efetuarRetirada");
    },
    concluido() {
      this.$store.dispatch('dashboard_retiradas/listaPedidosRetirada')
    }
  },
  filters: {
    formataDataHora(data) {
      if (!data) return;

      return moment(data).format("DD/MM/YYYY HH:mm");
    },
    formataMoeda(valor) {
      if (!valor) return "0,00";

      let vl = parseFloat(valor);
      return vl.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style> 