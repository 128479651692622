<template>
  <b-modal
    id="modalAssinantes"
    no-close-on-backdrop
    no-close-on-esc
    centered
    hide-footer
    size="xl"
    title="Assinantes"
  >
    <div class="mb-5">
      <b-table responsive hover :fields="fieldsAnual" :items="array_anual">
        <template #cell(valor_plano_mensal)="data">
          {{ data.item.valor_plano_mensal | formataMoeda }}
        </template>
        <template #cell(doze_meses)="data">
          {{ data.item.doze_meses | formataMoeda }}
        </template>
        <template #cell(trinta_e_seis_meses)="data">
          {{ data.item.trinta_e_seis_meses | formataMoeda }}
        </template>
      </b-table>
    </div>

    <div>
      <b-table class="mb-0" responsive hover :fields="fieldsMensal" :items="array_mensal">
        <template #cell(valor)="data">
          {{ data.item.valor | formataMoeda }}
        </template>
        <template #cell(doze_meses)="data">
          {{ data.item.doze_meses | formataMoeda }}
        </template>
        <template #cell(trinta_e_seis_meses)="data">
          {{ data.item.trinta_e_seis_meses | formataMoeda }}
        </template>
      </b-table>
      <b-table responsive :fields="fields" :items="total">
        <template #cell(quantidade)="data">
          <b>{{ data.item.quantidade }}</b>
        </template>
        <template #cell(doze_meses)="data">
          <b>R$ {{ data.item.doze_meses | formataMoeda }}</b>
        </template>
        <template #cell(trinta_e_seis_meses)="data">
          <b>R$ {{ data.item.trinta_e_seis_meses | formataMoeda }}</b>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>
 
<script>
import { BModal, BTable, BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    BModal,
    BTable,
    BRow,
    BCol,
  },
  props: ["assinaturas"],
  data() {
    return {
      array_anual: [],
      array_mensal: [],
      fieldsAnual: [
        { key: "quantidade", label: "Anual", thStyle:{ width: '20%'} },
        { key: "nome", label: "Plano", thStyle:{ width: '20%'} },
        { key: "valor_plano_mensal", label: "Valor Plano Mensal", thStyle:{ width: '20%'} },
        { key: "doze_meses", label: "12 Meses" },
        { key: "trinta_e_seis_meses", label: "36 Meses" },
      ],
      fieldsMensal: [
        { key: "quantidade", label: "Mensal", thStyle:{ width: '20%'} },
        { key: "nome", label: "Plano", thStyle:{ width: '20%'} },
        { key: "valor", label: "Valor Mensal", thStyle:{ width: '20%'} },
        { key: "doze_meses", label: "12 Meses" },
        { key: "trinta_e_seis_meses", label: "36 Meses" },
      ],
      fields: [
        { key: "quantidade", label: "Mensal", thStyle:{ width: '20%', opacity: '0.0'} },
        { key: "nome", label: "Plano", thStyle:{ width: '20%', opacity: '0.0'} },
        { key: "valor", label: "Valor Mensal", thStyle:{ width: '20%', opacity: '0.0'} },
        { key: "doze_meses", label: "12 Meses", thStyle: { opacity: '0.0'} },
        { key: "trinta_e_seis_meses", label: "36 Meses", thStyle: { opacity: '0.0'} },
      ],
    };
  },
  watch: {
    assinaturas() {
      this.montaArrayAnual();
      this.montaArrayMensal();
    },
  },
  computed: {
    total() {
      const total = [...this.array_anual, ...this.array_mensal].reduce(
        (acc, valor) => {
          acc.quantidade += valor.quantidade;
          acc.total_mensal += Number(valor.total_mensal);
          acc.doze_meses += Number(valor.doze_meses);
          acc.trinta_e_seis_meses += Number(
            valor.trinta_e_seis_meses
          );
          return acc;
        },
        {
          quantidade: 0,
          total_mensal: 0,
          doze_meses: 0,
          trinta_e_seis_meses: 0,
        }
      );

      return [total];
    },
  },

  methods: {
    montaArrayAnual() {
      this.array_anual = this.assinaturas.planos_anuais.map((element) => {
        const total_mensal = element.valor.toFixed(2);
        const valor_plano_mensal = (total_mensal / 12);
        const doze_meses = (total_mensal)
        const trinta_e_seis_meses = (total_mensal * 3)
        return {
          ...element,
          total_mensal,
          valor_plano_mensal,
          doze_meses,
          trinta_e_seis_meses,
        };
      });
      return this.array_anual;
    },
    montaArrayMensal() {
      this.array_mensal = this.assinaturas.planos_mensais.map((element) => {
        const total_mensal = element.valor.toFixed(2);
        const doze_meses = (total_mensal * 12)
        const trinta_e_seis_meses = (total_mensal * 36)
        return { ...element, total_mensal, doze_meses, trinta_e_seis_meses };
      });
      return this.array_mensal;
    },
  },
  filters: {
    formataMoeda(valor) {
      if (!valor) return "0,00";

      let vl = parseFloat(valor);
      return vl.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>

<style>
</style>